import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import store from "../store";
import { commonRoutes as informationSecurityRoutes } from "@monorepo/informationSecurity/src/router/commonRoutes";
import { commonRoutes as monitoringRoutes } from "@monorepo/monitoring/src/router/commonRoutes";
import { commonRoutes as inventoryRoutes } from "@monorepo/inventory/src/router/commonRoutes";
import { commonRoutes as loginRoutes } from "@monorepo/authorization/src/router/commonRoutes";
import { commonRoutes as catalogRoutes } from "@monorepo/catalog/src/router/commonRoutes";
import { commonRoutes as administrationRoutes } from "@monorepo/administration/src/router/commonRoutes";
import { commonRoutes as reportsRoutes } from "@monorepo/reports/src/router/commonRoutes";
import { commonRoutes as expertReviewCommissionRoutes } from "@monorepo/expertReviewCommission/src/router/commonRoutes";
import { addRouteGuards } from "@monorepo/authorization/src/router/utils";
import { customRouterPush } from "@monorepo/utils/src/utils/router/customRouterPush";

Vue.use(VueRouter);

const BlankView = () => import(/* webpackChunkName: "BlankView" */ "../views/Blank/BlankView.vue");
const HomeView = () => import(/* webpackChunkName: "HomeView" */ "../views/Home/HomeView.vue");
const ProfileView = () => import(/* webpackChunkName: "ProfileView" */ "../views/Profile/ProfileView.vue");

const additionalRoutes = [
  ...informationSecurityRoutes,
  ...loginRoutes,
  ...monitoringRoutes,
  ...inventoryRoutes,
  ...catalogRoutes,
  ...reportsRoutes,
  ...administrationRoutes,
  ...expertReviewCommissionRoutes,
];

const routes: Array<RouteConfig> = [
  ...additionalRoutes,
  {
    path: "/profile/:tab",
    component: ProfileView,
    meta: { requiresAuth: true, key: "profilePage", isNeedNavigationPanel: true },
  },
  {
    path: "/blank",
    component: BlankView,
    meta: { requiresAuth: true, key: "blankPage", isNeedNavigationPanel: true },
  },
  {
    path: "/",
    component: HomeView,
    meta: { requiresAuth: true, key: "homePage", isNeedNavigationPanel: true },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

customRouterPush();
addRouteGuards(router, store);

export default router;
