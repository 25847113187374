

















import { defineComponent } from "@vue/composition-api";
import { mapGetters, mapActions } from "vuex";
import { showNotification } from "@monorepo/utils/src/eventBus/utils/showNotification";
import { NOTIFICATION_STATUS } from "@monorepo/utils/src/eventBus/types/notification";
import CheckboxElement from "@monorepo/uikit/src/components/Settings/CheckboxElement.vue";
import SettingItemWrap from "@monorepo/uikit/src/components/Settings/SettingItemWrap.vue";

export default defineComponent({
  name: "StructureSettings",
  components: {
    CheckboxElement,
    SettingItemWrap,
  },
  props: {
    isShowValueTitle: {
      type: Boolean,
    },
    isCheckboxFirst: {
      type: Boolean,
      default: false,
    },
    filter: {
      type: Function,
    },
    notificationView: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      structureSettings: [] as {
        name: string;
        code: string;
        type: string;
        description: string;
      }[],
      settingsValues: {} as Record<string, unknown>,
    };
  },
  computed: {
    ...mapGetters("auth", ["user", "userSettings"]),
  },
  methods: {
    ...mapActions("app", ["getUserSettings", "getUserSettingsValues", "saveUserSettingsValues"]),
    ...mapActions("auth", ["getCommonUserSettingsValues"]),
    async saveSetting(
      currentValue: unknown,
      item: {
        name: string;
        code: string;
        type: string;
        description: string;
      }
    ) {
      const values = Object.entries(this.settingsValues).map(([codeSetting, value]) => {
        return {
          codeSetting,
          value: item.code === codeSetting ? currentValue : value,
        };
      });
      const isSave = await this.saveUserSettingsValues({ values });
      showNotification(
        !isSave ? "Ошибка сохранения настроек" : "Настройки успешно сохранены",
        !isSave ? NOTIFICATION_STATUS.ERROR : NOTIFICATION_STATUS.SUCCESS
      );
      await this.getCommonUserSettingsValues();
    },
  },
  watch: {
    "userSettings.NOTIFICATION_ENABLED": function (nv) {
      this.settingsValues["NOTIFICATION_ENABLED"] = nv;
    },
  },
  async mounted() {
    const settings = await this.getUserSettings();
    const values = await this.getUserSettingsValues();
    const objValues =
      values.reduce((res: Record<string, unknown>, value: { codeSetting: string; value: unknown }) => {
        res[value.codeSetting] = value.value;
        return res;
      }, {}) || {};
    this.structureSettings = settings;
    this.settingsValues = settings.reduce((res: Record<string, string>, item: { code: string; name: string }) => {
      res[item.code] = objValues[item.code] || false;
      return res;
    }, {});
  },
});
