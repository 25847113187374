



































import { defineComponent } from "@vue/composition-api";
import { mapMutations, mapGetters, mapActions } from "vuex";
import { environmentVariables } from "@monorepo/utils/src/variables/environmentVariables";
import CheckboxElement from "@monorepo/uikit/src/components/Settings/CheckboxElement.vue";
import SettingItemWrap from "@monorepo/uikit/src/components/Settings/SettingItemWrap.vue";
import StructureSettings from "@monorepo/app/src/views/Profile/components/info/StructureSettings.vue";

export default defineComponent({
  name: "ProfileModal",
  components: {
    CheckboxElement,
    SettingItemWrap,
    StructureSettings,
  },
  computed: {
    ...mapGetters("auth", ["user", "userSettings", "userAvatar"]),
    username(): string {
      return this.user?.name ?? "";
    },
    profileEmpty(): string {
      return `${environmentVariables.BASE_URL}assets/images/common/profile.svg`;
    },
    imageSrc() {
      return this.userAvatar || this.profileEmpty;
    },
  },
  methods: {
    ...mapActions(["logout"]),
    ...mapMutations("app", ["setIsOpenProfile"]),
    closeModal() {
      this.setIsOpenProfile(false);
    },
    resetPassword() {
      this.closeModal();
      this.$router.push("/reset-password");
    },
    goToProfile() {
      this.closeModal();
      this.$router.push("/profile/info");
    },
    async logoutCb() {
      this.setIsOpenProfile(false);
      await this.logout();
      await this.$router.push("/login");
    },
  },
});
