import { v4 as uuid } from "uuid";
import { environmentVariables } from "@monorepo/utils/src/variables/environmentVariables";
import { viewUniqKey as receiptFromOikViewUniqKey } from "@monorepo/utils/src/variables/projectsData/receiptFromOikView/viewTitle";
import { viewUniqKey as fundsReportsViewUniqKey } from "@monorepo/utils/src/variables/projectsData/fundsReportsView/viewTitle";
import { viewUniqKey as eadViewUniqKey } from "@monorepo/utils/src/variables/projectsData/eadView/viewTitle";
import { viewUniqKey as inventoryViewUniqKey } from "@monorepo/utils/src/variables/projectsData/inventoryView/viewTitle";
import { viewUniqKey as akViewUniqKey } from "@monorepo/utils/src/variables/projectsData/akView/viewTitle";
import { viewUniqKey as mrpViewUniqKey } from "@monorepo/utils/src/variables/projectsData/mrpView/viewTitle";
import { viewUniqKey as historicalNotesViewUniqKey } from "@monorepo/utils/src/variables/projectsData/historicalNotesView/viewTitle";
// import { viewUniqKey as nomenclatureViewUniqKey } from "@monorepo/utils/src/variables/projectsData/nomenclatureView/viewTitle";
import { viewUniqKey as tkLogViewViewUniqKey } from "@monorepo/utils/src/variables/projectsData/TKLogView/viewTitle";
import { viewUniqKey as eventLogViewViewUniqKey } from "@monorepo/utils/src/variables/projectsData/eventLog/viewTitle";
import { viewUniqKey as oikViewUniqKey } from "@monorepo/utils/src/variables/projectsData/oikView/viewTitle";
import { viewUniqKey as archiveViewUniqKey } from "@monorepo/utils/src/variables/projectsData/archiveView/viewTitle";
import { viewUniqKey as fundViewUniqKey } from "@monorepo/utils/src/variables/projectsData/fundView/viewTitle";
import { viewUniqKey as storageViewUniqKey } from "@monorepo/utils/src/variables/projectsData/storageView/viewTitle";
import { viewUniqKey as storageTermsViewUniqKey } from "@monorepo/utils/src/variables/projectsData/storageTerms/viewTitle";
import { viewUniqKey as docTypeViewUniqKey } from "@monorepo/utils/src/variables/projectsData/docTypeView/viewTitle";
import { viewUniqKey as versionCatalogViewUniqKey } from "@monorepo/utils/src/variables/projectsData/versionCatalogView/viewTitle";
import { viewUniqKey as TKListViewUniqKey } from "@monorepo/utils/src/variables/projectsData/TKListView/viewTitle";
import { viewUniqKey as systemNotificationViewUniqKey } from "@monorepo/utils/src/variables/projectsData/systemNotificationsList/viewTitle";
import { viewUniqKey as syncLogOikViewUniqKey } from "@monorepo/utils/src/variables/projectsData/syncLogOikView/viewTitle";
import { viewUniqKey as SettingsViewUniqKey } from "@monorepo/utils/src/variables/projectsData/settingsView/viewTitle";
import { viewUniqKey as systemAccessesViewViewUniqKey } from "@monorepo/utils/src/variables/projectsData/systemAccesses/viewTitle";
import { viewUniqKey as filestorageViewUniqKey } from "@monorepo/utils/src/variables/projectsData/filestorage/viewTitle";
import { viewUniqKey as InformationSecurityJournalViewUniqKey } from "@monorepo/utils/src/variables/projectsData/InformationSecurityJournal/viewTitle";
import { viewUniqKey as MonitoringBussinessProccesesViewUniqKey } from "@monorepo/utils/src/variables/projectsData/MonitoringBussinessProccesesView/viewTitle";
import { viewUniqKey as serviceDirectoriesViewUniqKey } from "@monorepo/utils/src/variables/projectsData/serviceDirectories/viewTitle";
import { viewUniqKey as ERCProtocolsViewUniqKey } from "@monorepo/utils/src/variables/projectsData/ERCProtocolsView/viewTitle";
import { viewUniqKey as externalApiViewUniqKey } from "@monorepo/utils/src/variables/projectsData/externalApi/viewTitle";
import { viewUniqKey as bugReportViewUniqKey } from "@monorepo/utils/src/variables/projectsData/bugReportView/viewTitle";
import { tabsTypes as systemAccessesTabs } from "@monorepo/utils/src/variables/projectsData/systemAccesses/tabsTypes";
import { authorities } from "@monorepo/utils/src/authorities/authorities";
import { viewUniqKey as generatedReportsViewUniqKey } from "@monorepo/utils/src/variables/projectsData/generatedReportsView/viewTitle";
import { viewUniqKey as eadStoragePeriodReportViewKey } from "@monorepo/utils/src/variables/projectsData/eadStoragePeriodReportView/viewTitle";
import { viewUniqKey as actsViewUniqKey } from "@monorepo/utils/src/variables/projectsData/actsView/viewTitle";
import { viewUniqKey as handoverViewUniqKey } from "@monorepo/utils/src/variables/projectsData/handoverView/viewTitle";
import { viewUniqKey as TkGenerationViewUniqKey } from "@monorepo/utils/src/variables/projectsData/tkGeneration/viewTitle";
import { viewUniqKey as inventoryReportViewUniqKey } from "@monorepo/utils/src/variables/projectsData/inventoryReportView/viewTitle";

import { viewUniqKey as receiptDocumentsBookReportUniqKey } from "@monorepo/utils/src/variables/projectsData/receiptDocumentsBookReport/viewTitle";
import { viewUniqKey as fundListReportUniqKey } from "@monorepo/utils/src/variables/projectsData/fundListReport/viewTitle";
import { viewUniqKey as fundsListReportUniqKey } from "@monorepo/utils/src/variables/projectsData/fundsListReport/viewTitle";
import { viewUniqKey as inventoriesRegisterReportUniqKey } from "@monorepo/utils/src/variables/projectsData/inventoriesRegisterReport/viewTitle";
import { viewUniqKey as passportArchiveReportUniqKey } from "@monorepo/utils/src/variables/projectsData/passportArchiveReport/viewTitle";
import { viewUniqKey as passportArchiveStoreReportUniqKey } from "@monorepo/utils/src/variables/projectsData/passportArchiveStoreReport/viewTitle";

export interface ISubNav {
  title: string;
  href?: string;
  customWidgetTitle?: string;
  id: string;
  key?: string;
  isSubHeader?: boolean;
  isMock?: boolean;
  isHideInHeadPage?: boolean;
  tooltip?: string;
  rights?: string[];
  date?: string;
  createDate?: string;
}

export const navigationItems: {
  id: string;
  icon: string;
  title: string;
  widgetAuthority?: string[];
  customWidgetTitle?: string;
  isMock: boolean;
  widgets?: string[];
  positionInHeadPage?: number;
  href: string;
  subNav: ISubNav[];
}[] = [
  {
    id: uuid(),
    icon: `${environmentVariables.BASE_URL}assets/images/navigation/menu/comission.svg`,
    title: "Экспертно-проверочная комиссия",
    isMock: true,
    href: "/erc",
    subNav: [
      {
        title: "Список проектов описей дел",
        key: ERCProtocolsViewUniqKey,
        href: `/erc/inventory`,
        id: uuid(),
        isHideInHeadPage: true,
        rights: [authorities.EXPERT_COMMISSION_LIST],
      },
    ],
  },
  {
    id: uuid(),
    icon: `${environmentVariables.BASE_URL}assets/images/navigation/menu/eventLog.svg`,
    title: "Учёт",
    href: "/inventory",
    widgets: ["InventoryWidgetInventory", "InventoryWidgetEad"],
    widgetAuthority: [authorities.INVENTORY_LIST, authorities.EAD_LIST],
    positionInHeadPage: 0,
    isMock: true,
    subNav: [
      {
        title: "Список описей дел",
        key: ERCProtocolsViewUniqKey,
        href: `/inventory/case-notes-list`,
        id: uuid(),
        rights: [authorities.INVENTORY_LIST],
      },
      {
        title: "Список дел",
        key: inventoryViewUniqKey,
        href: "/inventory/case",
        id: uuid(),
        rights: [authorities.CASE_LIST],
      },
      {
        title: "Список ЭАД",
        key: eadViewUniqKey,
        href: "/inventory/ead",
        id: uuid(),
        rights: [authorities.EAD_LIST],
      },
      {
        title: "Список актов приёма-передачи дел",
        key: actsViewUniqKey,
        href: "/inventory/acts",
        id: uuid(),
        isHideInHeadPage: true,
        rights: [authorities.CASE_ACT_LIST],
      },
      {
        title: "Список ТК",
        key: TKListViewUniqKey,
        href: "/inventory/tk",
        id: uuid(),
        rights: [authorities.TK_DOCUMENTS_LIST, authorities.TK_DICTIONARIES_LIST, authorities.TK_OTHER_LIST],
      },
      {
        title: "Список АК",
        key: akViewUniqKey,
        href: "/inventory/ak",
        id: uuid(),
        rights: [authorities.AK_LIST],
      },
      {
        title: "Реестр МЧД",
        key: mrpViewUniqKey,
        href: "/inventory/mrp",
        id: uuid(),
        //rights: [authorities.AK_LIST],
      },
      {
        title: "Список исторических справок",
        key: historicalNotesViewUniqKey,
        href: "/inventory/historical-notes",
        id: uuid(),
      },
      {
        title: "График передачи дел",
        key: handoverViewUniqKey,
        href: "/inventory/case-transfer-years",
        id: uuid(),
        isHideInHeadPage: true,
        rights: [authorities.OIK_CASE_TRANSFER_LIST],
      },
    ],
  },
  {
    id: uuid(),
    icon: `${environmentVariables.BASE_URL}assets/images/navigation/menu/libraries.svg`,
    title: "Справочники",
    widgets: ["OikWidget"],
    widgetAuthority: [authorities.OIK_LIST],
    href: "/libraries",
    positionInHeadPage: 2,
    isMock: true,
    subNav: [
      {
        title: "Справочник ОИК",
        key: oikViewUniqKey,
        href: "/dictionaries/oik",
        id: uuid(),
        rights: [authorities.OIK_LIST],
      },
      {
        title: "Справочник архивов",
        key: archiveViewUniqKey,
        href: "/dictionaries/archive",
        id: uuid(),
        rights: [authorities.ARCHIVE_LIST],
      },
      {
        title: "Справочник фондов",
        key: fundViewUniqKey,
        href: "/dictionaries/fund",
        id: uuid(),
        rights: [authorities.FUND_LIST],
      },
      {
        title: "Реестр версий справочников видов документов",
        key: versionCatalogViewUniqKey,
        href: "/dictionaries/versions",
        id: uuid(),
        rights: [authorities.DOC_KIND_LIST],
      },
      {
        title: "Справочник Перечень видов документов",
        key: docTypeViewUniqKey,
        href: "/dictionaries/doc-type",
        id: uuid(),
        rights: [authorities.DOC_KIND_LIST],
      },
      {
        title: "Служебные справочники",
        key: serviceDirectoriesViewUniqKey,
        href: "/dictionaries/service-dictionaries",
        isHideInHeadPage: true,
        id: uuid(),
        rights: [authorities.SERVICE_DIRECTORIES_LIST],
      },
      {
        title: "Справочник мест хранения",
        key: storageViewUniqKey,
        href: "/dictionaries/storage",
        isHideInHeadPage: true,
        id: uuid(),
        rights: [authorities.DOC_KIND_LIST],
      },
      {
        title: "Справочник сроков хранения",
        key: storageViewUniqKey,
        href: "/dictionaries/storage-terms",
        isHideInHeadPage: true,
        id: uuid(),
        rights: [authorities.DOC_KIND_LIST],
      },
    ],
  },
  {
    id: uuid(),
    icon: `${environmentVariables.BASE_URL}assets/images/navigation/menu/report.svg`,
    title: "Учётные формы",
    customWidgetTitle: "Отчеты",
    href: "/report",
    widgetAuthority: [authorities.REPORT_LIST],
    widgets: ["ReportWidget"],
    positionInHeadPage: 3,
    isMock: true,
    subNav: [
      {
        title: "Книга учета поступлений документов",
        key: receiptDocumentsBookReportUniqKey,
        href: "/reports/archived-documents",
        id: uuid(),
        rights: [authorities.REPORT_LIST],
      },
      {
        title: "Список фондов",
        key: fundsListReportUniqKey,
        href: "/reports/funds-list",
        id: uuid(),
        rights: [authorities.REPORT_LIST],
      },
      {
        title: "Лист фонда",
        key: fundListReportUniqKey,
        href: "/reports/fund-list",
        id: uuid(),
        rights: [authorities.REPORT_LIST],
      },
      {
        title: "Опись дел, документов",
        key: inventoryReportViewUniqKey,
        href: "/reports/inventory-report",
        id: uuid(),
        rights: [authorities.REPORT_LIST],
      },
      {
        title: "Реестр описей дел, документов",
        key: inventoriesRegisterReportUniqKey,
        href: "/reports/inventories-register-report",
        id: uuid(),
        rights: [authorities.REPORT_LIST],
      },
      {
        title: "Паспорт архива",
        key: passportArchiveReportUniqKey,
        href: "/reports/passport-archive-report",
        id: uuid(),
        rights: [authorities.REPORT_LIST],
      },
      {
        title: "Паспорт архивохранилища",
        key: passportArchiveStoreReportUniqKey,
        href: "/reports/passport-archive-store-report",
        id: uuid(),
        rights: [authorities.REPORT_LIST],
      },
      {
        title: "Отчеты",
        isSubHeader: true,
        id: uuid(),
        rights: [authorities.REPORT_GENERATED_LIST, authorities.REPORT_LIST],
      },
      {
        title: "Поступившие документы",
        key: receiptFromOikViewUniqKey,
        href: "/reports/received-documents",
        id: uuid(),
        rights: [authorities.REPORT_LIST],
      },
      {
        title: "Отчёт по фондам",
        key: fundsReportsViewUniqKey,
        href: "/reports/funds-reports",
        id: uuid(),
        rights: [authorities.REPORT_LIST],
      },
      {
        title: "Окончание сроков хранения ЭАД",
        key: eadStoragePeriodReportViewKey,
        href: "/reports/ead-storage-reports",
        id: uuid(),
        rights: [authorities.REPORT_LIST],
      },
      {
        title: "Построенные отчёты",
        key: generatedReportsViewUniqKey,
        href: "/reports/generated-reports",
        isHideInHeadPage: true,
        id: uuid(),
        rights: [authorities.REPORT_GENERATED_LIST],
      },
    ],
  },
  {
    id: uuid(),
    icon: `${environmentVariables.BASE_URL}assets/images/navigation/menu/monitoring.svg`,
    title: "Мониторинг",
    href: "/monitoring",
    widgetAuthority: [authorities.TK_JOURNAL_LIST],
    widgets: ["TKStatusWidget"],
    positionInHeadPage: 1,
    isMock: true,
    subNav: [
      {
        title: "Мониторинг",
        key: MonitoringBussinessProccesesViewUniqKey,
        href: "/monitoring/monitoring",
        id: uuid(),
        rights: [authorities.MONITORING_LIST],
      },
      {
        title: "Журнал обработки ТК",
        key: tkLogViewViewUniqKey,
        href: "/monitoring/tk_processing_log",
        id: uuid(),
        rights: [authorities.TK_JOURNAL_LIST],
      },
      {
        title: "Журнал событий",
        key: eventLogViewViewUniqKey,
        href: "/monitoring/event-log",
        id: uuid(),
        rights: [authorities.EVENT_LOG_LIST],
      },
      {
        title: "Журнал обновления справочников в ОИК",
        key: syncLogOikViewUniqKey,
        href: "/monitoring/sync-log-oik/oikTab",
        id: uuid(),
        rights: [authorities.OIK_LOG_LIST],
      },
    ],
  },
  {
    id: uuid(),
    icon: `${environmentVariables.BASE_URL}assets/images/navigation/menu/informationSecurityItem.svg`,
    title: "Информационная безопасность",
    widgets: ["InformationSecurityWidget"],
    widgetAuthority: [authorities.INFORMATION_SECURITY_JOURNAL_LIST],
    isMock: true,
    href: "/information-security",
    positionInHeadPage: 4,
    subNav: [
      {
        title: "Журнал информационной безопасности",
        key: InformationSecurityJournalViewUniqKey,
        href: "/information-security/information-security-log",
        id: uuid(),
        rights: [authorities.INFORMATION_SECURITY_JOURNAL_LIST],
      },
      {
        title: "Роли/Пользователи",
        key: systemAccessesViewViewUniqKey,
        href: `/information-security/system-access/${systemAccessesTabs?.roles?.url}`,
        id: uuid(),
        rights: [authorities.ROLE_LIST, authorities.USER_LIST],
      },
      {
        title: "Внешнее API - пользователи",
        key: externalApiViewUniqKey,
        href: `/information-security/external-api-users`,
        id: uuid(),
        rights: [authorities.USER_LIST],
      },
    ],
  },
  {
    id: uuid(),
    icon: `${environmentVariables.BASE_URL}assets/images/navigation/menu/administration.svg`,
    title: "Администрирование",
    positionInHeadPage: 5,
    widgets: ["BugReportWidget"],
    widgetAuthority: [authorities.ERROR_MSG_LIST],
    isMock: true,
    href: "/administration",
    subNav: [
      {
        title: "Генерация массива ТК",
        key: TkGenerationViewUniqKey,
        href: "/administration/tk-generation",
        isHideInHeadPage: true,
        id: uuid(),
        rights: [authorities.TK_GENERATION],
      },
      {
        title: "Системные уведомления",
        key: systemNotificationViewUniqKey,
        href: "/administration/system-notifications",
        isHideInHeadPage: true,
        id: uuid(),
        rights: [authorities.NOTIFICATION_LIST],
      },
      {
        title: "Хранилище данных ЦХЭД",
        key: filestorageViewUniqKey,
        href: "/administration/filestorage",
        id: uuid(),
        rights: [authorities.DATA_STORAGE_LIST],
      },
      {
        title: "Настройки",
        key: SettingsViewUniqKey,
        href: "/administration/settings",
        id: uuid(),
        rights: [authorities.SETTINGS_LIST],
      },
      {
        title: "Журнал сообщений об ошибке",
        key: bugReportViewUniqKey,
        href: "/administration/bug-reports",
        id: uuid(),
        rights: [authorities.ERROR_MSG_LIST],
      },
    ],
  },
];
