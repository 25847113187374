





















import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  name: "CheckboxElement",
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    code: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: "",
    },
    tooltip: {
      type: String,
      default: "",
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    isCheckboxFirst: {
      type: Boolean,
      default: false,
    },
    isShowValueTitle: {
      type: Boolean,
      default: true,
    },
  },
});
